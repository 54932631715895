
import {computed, defineComponent, ref, watch} from "vue";
import {
  IonBackButton,
  IonButton, IonButtons,
  IonCard, IonCardContent, IonContent, IonGrid, IonHeader, IonLabel,
  IonPage, IonRow, IonTitle, IonToolbar, loadingController, onIonViewWillEnter,


} from "@ionic/vue";
import {useStore} from "vuex";
import CheckCreateEntryDeliveryItemComponent from "@/app/view/delivery/check/CheckCreateEntryDeliveryItemComponent.vue";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {CreateEntryDelivery, UpdateEntryDelivery} from "@/domain/command/delivery/delivery.command";
import router from "@/app/router";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import {useRoute} from "vue-router";


export default defineComponent({
  name: "CheckCreateEntryDeliveryPage",
  components: {
    IonTitle,
    IonHeader,
    IonButton,
    IonBackButton,
    IonPage,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonContent,
    IonToolbar,
    IonButtons,
    // IonLabel,
    CheckCreateEntryDeliveryItemComponent

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const {resetCheckUpdateEntryDeliveryState} = useViewStateApi();
    const entryDeliveryForCheck = computed<UpdateEntryDelivery>(() => store.getters.getCheckUpdateEntryDeliveryState);

    const {updateEntryDelivery} = useDeliveryApi();
    const isSubmitting = ref(false);


    const submit = async () => {
      if (isSubmitting.value)
        return;

      const loading = await loadingController
          .create({
            message: 'Guardando albarán de entrada',
            duration: 10000,
          });

      await loading.present();
      const newCommand: UpdateEntryDelivery = entryDeliveryForCheck.value;

      await updateEntryDelivery(newCommand);
      resetCheckUpdateEntryDeliveryState();

      await loading.dismiss();
      await router.push({path: `/app/albaran/historial`})
    };


    return {entryDeliveryForCheck, submit};
  }
})
